<template>
  <form data-name="mcrConfig"
    class="mcrConfig">
    <div class="header"
      role="presentation">
      <!-- Sub-Title: MCR Connection Details -->
      <h3 v-if="!hideTitle"
        class="text-align-center mb-3">
        {{ $t('connections.mcr-config', { end }) }}
      </h3>

      <!-- Enable QinQ -->
      <template v-if="!autoConfigureMCR">
        <mu-form-group class="qinqToggle"
          data-name="qinqOption"
          :label="$t('mcr-config.enable-qinq')"
          :tooltip="$t('connections.mcr-setup-tooltip')"
          :stacked="true">
          <!-- Enable QinQ: Toggle -->
          <el-switch :value="qinqEnabled"
            name="qinqEnabled"
            :disabled="readonly"
            data-testid="qinq-enabled"
            :active-text="$t('general.enabled')"
            :inactive-text="$t('general.disabled')"
            @change="confirmToggleQinQ" />
        </mu-form-group>
      </template>
    </div>

    <hr v-if="!qinqEnabled">

    <template v-if="!autoConfigureMCR">
      <!-- VLAN Tabs -->
      <template v-if="qinqEnabled">
        <div class="vlanTabs">
          <!-- VLAN Tabs -->
          <el-tabs v-model="selectedTab"
            @tab-click="selectVlanInterface">
            <el-tab-pane v-for="(vlan, index) in vlanList"
              :key="`vlan-${index}`"
              :name="index.toString()">
              <template #label>
                {{ $t('connections.vlan') }} {{ vlan }} <span v-if="!vlanIdErrors[index]">- {{ $t('mcr-config.invalid') }}</span>
              </template>
            </el-tab-pane>
          </el-tabs>

          <!-- Add VLAN -->
          <el-button v-if="interfaces.length < maxVlan"
            :disabled="!isConfigurationValid"
            class="addVlanBtn"
            type="primary"
            size="small"
            @click="addInterface">
            {{ $t('mcr-config.add-vlan') }}
          </el-button>
        </div>

        <!-- VLAN input -->
        <!-- @candidate for component -->
        <div v-if="qinqEnabled"
          class="section "
          role="section">
          <h5>{{ $t('mcr-config.virtual-lan') }}</h5>
          <p>{{ $t('mcr-config.unique-id') }}</p>
          <div class="vlanInputGroup">
            <mu-form-group :label="$t('mcr-config.vlan-id')"
              required>
              <el-input class="vlanInput"
                name="VLAN-Input"
                :disabled="readonly"
                type="number"
                min="1"
                :max="maxVlanId"
                :value="selectedInterface.vlan"
                @input="updateVLAN" />
              <mu-form-alert v-if="vlanDuplicates.includes(selectedInterface.vlan) || !vlanIdErrors[selectedInterfaceIndex]"
                type="error"
                :message="$t('validations.vlan-unique', { max: maxVlanId })" />
            </mu-form-group>

            <div>
              <el-button v-if="interfaces.length > 1"
                class="deleteVlanBtn"
                type="danger"
                size="small"
                plain
                @click="confirmDeleteVLAN">
                {{ $t('mcr-config.delete-vlan') }}
              </el-button>
            </div>
          </div>
        </div>

        <hr>
      </template>

      <!-- Connection Details -->
      <div class="section-flex section"
        role="section">
        <!-- Interface IP Addresses -->
        <div class="col networkPrefixes"
          role="presentation">
          <h5>{{ $t('mcr-config.interface-ip-addresses') }}</h5>

          <mu-form-group data-name="network-prefix-list"
            data-testid="network-prefix-list"
            :label="$t('mcr-config.ip-subnet')"
            :tooltip="$t('connections.mcr-ip-address-tooltip')"
            stacked>
            <mu-network-address-list ref="MuNetworkAddress"
              :default-value="selectedInterface.ipAddresses"
              :max="maxIpAddresses"
              :disabled-list="usedNetworkPrefix"
              @update="updateNetworkAddress"
              @delete="deleteNetworkAddress" />
          </mu-form-group>
        </div>

        <!-- Network Address Translation (NAT) -->
        <div class="col nat pl-3 ml-2"
          role="presentation">
          <h5>{{ $t('mcr-config.nat') }}</h5>

          <mu-form-group :label="$t('mcr-config.nat-source')"
            :tooltip="$t('mcr-config.nat-source-tooltip')"
            stacked>
            <el-select class="selectNatAddressInput"
              clearable
              :value="selectedNat"
              name="natIPAddress"
              data-testid="nat-ip-address"
              :disabled="!allowConfiguration || !selectedInterfaceIpAddressesIpV4.length"
              @change="selectNatAddress">
              <el-option v-for="address in selectedInterfaceIpAddressesIpV4"
                :key="address"
                :value="address.split('/')[0]" />
            </el-select>
          </mu-form-group>

          <el-tag v-if="!!selectedNat"
            type="info"
            class="natStatus mt-1"
            role="complementary">
            {{ $t('mcr-config.nat-enabled') }}
            <mu-fa-icon :aria-label="$t('mcr-config.nat-is-enabled')"
              icon="faCheck"
              class="greenIcon" />
          </el-tag>
        </div>
      </div>

      <hr>

      <!-- BGP Connections -->
      <div data-name="bgpConnections"
        class="section bgpConnections"
        role="section">
        <div class="flex-row"
          role="presentation">
          <div role="presentation">
            <h5>{{ $t('connections.bgp-connections') }}</h5>

            <p>{{ $t('mcr-config.bgp-connections-description') }}</p>
          </div>
          <div>
            <el-button class="addBgpConnection"
              type="primary"
              :disabled="!allowBgpConnection"
              size="mini"
              name="addBgpConnection"
              @click="addBGPConnection">
              {{ $t('mcr-config.bgp-add') }}
            </el-button>
          </div>
        </div>

        <!-- BGP Connections Table -->
        <mu-bgp-connections-list ref="MuBgpConnectionList"
          data-testid="bgp-connection-list"
          class="mt-1"
          :readonly="readonly"
          :selected-interface="selectedInterface"
          @edit="selectBGPConnection"
          @shutdown="handleBGPShutdown"
          @delete="deleteBGPConnection" />
      </div>

      <hr>

      <!-- Static Routes -->
      <div name="staticRoutes"
        class="section mb-2"
        role="section">
        <h5 class="mb-1">
          {{ $t('connections.static-routes') }}
        </h5>

        <mu-static-routes ref="MuStaticRoutes"
          data-testid="static-routes-list"
          :prefix-list="selectedInterface.ipAddresses"
          :read-only="!allowConfiguration"
          :ip-routes="selectedInterfaceIpRoutes"
          :max="maxStaticRoutes"
          @update="updateStaticRoutes" />
      </div>
    </template>

    <template v-if="autoConfigureMCR">
      <p data-name="autoconfigure-1">
        {{ $t('ports.autoconfig-mcr') }}
      </p>
      <p data-name="autoconfigure-2">
        {{ $t('connections.new-bgp-state', { state: bgpDefaultStateShutdown ? $t('connections.shutdown') : $t('connections.enabled')}) }}
      </p>
    </template>

    <!-- BGP Connection Editor Modal -->
    <el-dialog :title="$t('mcr-config.bgp-connection')"
      class="bgpConnectionEditor"
      :visible.sync="showAddBGPConnection"
      :append-to-body="serviceKey"
      @close="resetBGPConnectionForm">
      <mu-bgp-connection-editor v-if="bgpConnectionForm"
        ref="MuBgpConnectionEditor"
        :disabled="!allowConfiguration"
        :port="mcrPort"
        :index="bgpConnectionIndex"
        :default-value="bgpConnectionForm"
        :selected-interface="selectedInterface"
        :port-bgp-connections="bpgPeersList"
        :prefix-lists="prefixLists"
        @update="updateBGPConnection" />

      <template #footer>
        <!-- Cancel -->
        <el-button :disabled="false"
          name="cancelAddBgpConnection"
          @click="showAddBGPConnection = false">
          {{ $t('general.cancel') }}
        </el-button>
        <!-- Add/Update -->
        <el-button v-if="!readonly"
          type="primary"
          :disabled="!validationMap.bgpConnection"
          name="saveAddBgpConnection"
          @click="commitBGPConnection">
          {{ bgpConnectionIndex === -1 ? $t('general.add') : $t('general.update') }}
        </el-button>
      </template>
    </el-dialog>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { networkContainsAddress } from 'netparser'
import ipRegex from 'ip-regex'
import { MEGAPORT_ASN } from '@/Globals.js'

export default {
  name: 'MCRConfig',

  props: {
    config: {
      type: Object,
      required: true,
    },
    serviceObj: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    mcrPort: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    end: {
      type: String,
      required: true,
    },
    // If connection is for service key
    serviceKey: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      originalConfig: null,
      maxIpAddresses: 5,
      maxBgpConnections: 5,
      maxVlan: 5,
      maxVlanId: 4094,
      maxStaticRoutes: 100,
      qinqEnabled: false,
      interfaces: [],
      showAddBGPConnection: false,
      bgpConnectionIndex: 0,
      bgpConnectionForm: {},
      bfdUpdate: null,
      selectedInterfaceIndex: 0,
      // Track validation states: true = valid, false = invalid
      validationMap: {
        ipRoutes: true,
        ipAddresses: true,
        bgpConnection: true,
      },
      vlanIdErrors: [],
    }
  },

  computed: {
    ...mapGetters('RouteFiltering', ['prefixLists']),
    ...mapGetters('Auth', ['hasFeatureFlag']),
    /**
     * VM for VLAN tabs
     * @param {String} tabIndex string value of tab name
     * @returns {String} selected index as string (for tabs)
     */
    selectedTab: {
      get: function() {
        return this.selectedInterfaceIndex.toString()
      },
      set: function(tabIndex) {
        this.selectedInterfaceIndex = Number.parseInt(tabIndex)
      },
    },
    /**
     * @returns {Object} Selected interface using selectedInterfaceIndex
     */
    selectedInterface() {
      if (!this.interfaces) return {}
      return {
        vlan: null,
        ipAddresses: [],
        bgpConnections: [],
        ipRoutes: [],
        natIpAddresses: [],
        ...this.interfaces[this.selectedInterfaceIndex],
      }
    },

    /**
     * @returns {Array} List of IPv4 addresses in selected interface
     */
    selectedInterfaceIpAddressesIpV4() {
      return this.selectedInterface.ipAddresses.filter(address => {
        return ipRegex.v4().test(address)
      })
    },

    /**
     * @returns {Array} List of BGP connections in selected interface
     */
    selectedInterfaceBgpConnections() {
      return this.selectedInterface.bgpConnections
    },

    /**
     * @returns {Array} List of IP Routes in selected interface
     */
    selectedInterfaceIpRoutes() {
      return this.selectedInterface.ipRoutes
    },

    /**
     * @returns {Array} List of prefixes connected to NAT, BGP and Static Routes
     * USed to disables the network input once it's been used
     */
    usedNetworkPrefix() {
      // Is the prefix used in a BGP connection?
      const bgpLocalIp = this.selectedInterfaceBgpConnections.map(connection => connection.localIpAddress)

      // Is the prefix used as the NAT address?
      const nat = this.selectedInterface.natIpAddresses[0]

      // Is the prefix used in a static route?
      const staticRoutes = this.staticRouteNextHopsMatchingInterface.map(route => route.interfaceIp)

      // Merge into a list
      const usedAddresses = [...bgpLocalIp, nat]
      const usedPrefixes = this.selectedInterface.ipAddresses.filter(address => {
        return usedAddresses.includes(address.split('/')[0])
      })
      return Array.from(new Set([...usedPrefixes, ...staticRoutes]))
    },
    /**
     * @returns {Array<Object>} list of matching interface IPs and route index
     */
    staticRouteNextHopsMatchingInterface() {
      const nextHops = this.selectedInterfaceIpRoutes.map(route => route.nextHop)

      return this.selectedInterface.ipAddresses.reduce((listMap, address) => {
        nextHops.forEach((route, index) => {
          if (networkContainsAddress(address, route)) {
            listMap.push({ index: index, interfaceIp: address })
          }
        })
        return listMap
      }, [])
    },
    /**
     * @todo - confirm this is the only case
     * @returns Default bgp shutdown state for autoconfigured ports
     */
    bgpDefaultStateShutdown() {
      if (!this.mcrPort?.config || !this.mcrPort?.resources) return false
      if (this.mcrPort.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        return this.mcr.config.bgpShutdownDefault || false
      }
      return this.mcrPort.resources.virtual_router.bgpShutdownDefault
    },

    /**
     * @returns {Boolean} Is connection automatically configured by the network
     */
    autoConfigureMCR() {
      // Service keys require manual configuration
      if (this.serviceKey) return false

      const unconfigured = ![this.G_PROVISIONING_LIVE, this.G_PROVISIONING_CONFIGURED, this.G_PROVISIONING_DEPLOYABLE]
        .includes(this.serviceObj.provisioningStatus)

      if (unconfigured) {
        const connectType = this.serviceObj.bEnd.partnerConfig.connectType

        switch (connectType) {
          case 'AZURE':
          case 'AWS':
          case 'GOOGLE':
          case 'SFDC':
          case 'OUTSCALE':
          case 'IBM':
          case 'TRANSIT':
            return true
          default:
            return false
        }
      }
      return false
    },

    /**
     * @returns {Boolean} Does the connection have any interfaces or read only
     */
    allowConfiguration() {
      return !!this.selectedInterface.ipAddresses.length && !this.readonly
    },

    /**
     * @returns {Boolean} Used to disable Add BGP Connection when max connections reached
     */
    allowBgpConnection() {
      return this.allowConfiguration && this.selectedInterface.bgpConnections.length < this.maxBgpConnections
    },

    /**
     * @returns {String} Selected NAT address or Null
     */
    selectedNat() {
      if (!this.selectedInterface.natIpAddresses.length) return null
      return this.selectedInterface.natIpAddresses[0]
    },

    /**
     * @returns {Boolean} When route is for new connection
     */
    isNewConnection() {
      return this.$route.name === 'CreateConnection'
    },

    /**
     * @returns {Boolean} BFD enabled on any BGP connection
     */
    isBfdEnabled() {
      return this.selectedInterfaceBgpConnections.some(connection => connection.bfdEnabled)
    },

    /**
     * @returns {Array} List of VLAN IDs
     */
    vlanList() {
      return this.interfaces.map(iface => iface.vlan).filter(vlan => !!vlan)
    },

    /**
     * @returns {Array} List of duplicate VLAN IDs
     */
    vlanDuplicates() {
      return this.vlanList.filter((vlan, index, arr) => {
        return arr.indexOf(vlan) !== index
      })
    },

    /**
     * @returns {Boolean} If the configuration is valid; default true
     */
    isConfigurationValid() {
      return Object.values(this.validationMap).every(value => value) && this.vlanIdErrors.every(value => value)
    },
    /**
     * @returns {Array} List of BGP peers on the MCR port
     */
    bpgPeersList() {
      if (!this.mcrPort.associatedVxcs) return []

      return this.mcrPort.associatedVxcs.reduce((bgpConnectionsList, vxc) => {
        if (![this.G_PROVISIONING_LIVE, this.G_PROVISIONING_DEPLOYABLE, this.G_PROVISIONING_CONFIGURED].includes(vxc.provisioningStatus))
          return bgpConnectionsList

        const cspEnd = vxc.aEnd.productUid === this.mcrPort.productUid ? 'a_csp_connection' : 'b_csp_connection'
        let cspConnections = []

        // csp_connection is sometimes an object
        if (Array.isArray(vxc.resources?.csp_connection)) {
          cspConnections = vxc.resources.csp_connection.filter(csp => {
            return csp.connectType === 'VROUTER' && csp.resource_name === cspEnd
          })
        } else if (vxc.resources?.csp_connection?.resource_name === cspEnd) {
          cspConnections = [vxc.resources.csp_connection]
        }

        cspConnections.forEach(connection => {
          if (!connection.interfaces) return true
          connection.interfaces.forEach(iface => {
            if (Object.keys(iface).includes('bgpConnections')) {
              iface.bgpConnections.forEach(bgp => {
                bgpConnectionsList.push(bgp)
              })
            }
          })
        })

        return bgpConnectionsList
      }, [])
    },
  },

  watch: {
    config() {
      // parent config (connectionForm) is synchronous with child
      // allows revert button to work
      this.setupConfig()
    },
  },

  created() {
    // Backup original config for shutdown
    this.originalConfig = { ...this.config }
    // Get the MCR prefix lists
    if (this.mcrPort.productType === this.G_PRODUCT_TYPE_MCR2
      && this.mcrPort.provisioningStatus !== this.G_PROVISIONING_DESIGN
      && !this.autoConfigureMCR) {
      this.getPrefixLists(this.mcrPort)
    }
    // Setup the configuration
    this.setupConfig()
    // Update vlan validation
    this.checkInterfaces()
  },

  methods: {
    ...mapActions('RouteFiltering', ['getPrefixLists']),
    /**
     * Setup for create/edit
     */
    setupConfig() {
      const interfacesExist = this.config.partnerConfig.interfaces?.length > 0

      if (interfacesExist) {
        this.interfaces = [...this.config.partnerConfig.interfaces]
      } else if (this.isNewConnection && !this.autoConfigureMCR) {
        // New empty configuration
        this.addInterface()
      }

      // Edit existing or saved configuration
      if (!this.autoConfigureMCR) {
        // Azure qinqEnabled enabled by default
        if (this.config.partnerConfig.connectType === 'AZURE') this.qinqEnabled = true

        // QinQ enabled
        if (interfacesExist && this.config.partnerConfig.interfaces[0].vlan) this.qinqEnabled = true
      }
    },
    /**
     * Check if interfaces exist and update vlan id valdation
     */
    checkInterfaces() {
      const interfacesExist = !!this.config.partnerConfig.interfaces
      if (interfacesExist && this.qinqEnabled) {
        this.config.partnerConfig.interfaces.forEach((iface, index) => {
          this.validateVLAN(index, iface.vlan, false)
        })
      }
    },

    // -------------------------------------------------------------------------------------------- BGP Connections

    /**
     * @returns {Object} Empty BGP Connection shape
     * All values default to null
     */
    newBgpConnection() {
      let asnValue = this.mcrPort?.config?.mcrAsn ?? this.mcrPort?.resources?.virtual_router?.mcrAsn ?? MEGAPORT_ASN
      return {
        peerAsn: null,
        localIpAddress: null,
        peerIpAddress: null,
        password: null,
        localAsn: asnValue,
        shutdown: false,
        description: null,
        medIn: null,
        medOut: null,
        bfdEnabled: false,
        importWhitelist: null,
        importBlacklist: null,
        exportWhitelist: null,
        exportBlacklist: null,
        exportPolicy: null,
        denyExportTo: [],
        permitExportTo: [],
      }
    },

    /**
     * Handler to select BGP connection for editing
     * Triggers BGP connection modal
     * @param {Number} index
     * @param {Object} row BGP Conection shape
     */
    selectBGPConnection({ index, row }) {
      this.bgpConnectionIndex = index
      this.bgpConnectionForm = { ...row }
      this.validationMap = { ...this.validationMap, bgpConnection: true }
      this.showAddBGPConnection = true
    },

    /**
     * Update handler for BGP Connection Editor
     * @param {Boolean} valid
     * @param {Object} bgpConnection BGP Connection shape
     * @param {Object} bfd BFD shape
     */
    updateBGPConnection({ valid, bgpConnection }) {
      this.validationMap = { ...this.validationMap, bgpConnection: valid }
      // Update the connection
      if (valid) {
        // Update the connection form
        this.bgpConnectionForm = bgpConnection
      } else {
        this.emitEdit()
      }
    },

    /**
     * Click handler for Add New BGP Connection button
     * Creates a blank BGP Connection form
     */
    addBGPConnection() {
      this.bgpConnectionIndex = -1
      this.bgpConnectionForm = this.newBgpConnection()
      this.validationMap = { ...this.validationMap, bgpConnection: false }
      this.showAddBGPConnection = true
    },

    /**
     * Add and update handler for BGP Connection Editor
     * Buttons are disabled if form is not valid
     */
    commitBGPConnection() {
      // Grab the index (if new connection increment list)
      const bgpIndex = this.bgpConnectionIndex < 0 ? this.selectedInterfaceBgpConnections.length++ : this.bgpConnectionIndex

      // Update BFD if required
      const updateBfd = !!this.bfdUpdate
      if (updateBfd) this.updateSelectedInterface({ bfd: this.bfdUpdate })

      // Update bgp connections
      const update = [...this.selectedInterface.bgpConnections]
      update.splice(bgpIndex, 1, this.bgpConnectionForm)
      this.updateSelectedInterface({ bgpConnections: update })

      this.showAddBGPConnection = false
    },

    /**
     * Close event handler for BGP Connection editor -- resets the bgpConnection form
     */
    resetBGPConnectionForm() {
      this.bgpConnectionForm = null
    },

    /**
     * Click handler for BGP Shutdown
     */
    handleBGPShutdown({ index, row }) {
      // Set edit index
      this.bgpConnectionIndex = index

      // Update
      const update = { ...row, shutdown: !row.shutdown }

      // Count bgp connections
      // Use keys to check key length, not index length. E.g const a = []; a[30] = 'foo'; a.length = 31 )
      let savedBgpCount = 0
      const interfacesExist = !!this.originalConfig.partnerConfig.interfaces
      if (interfacesExist) {
        // If there were no bgp connections before, there may be no bgpConnections defined
        const existingConnections = this.originalConfig.partnerConfig.interfaces[this.selectedInterfaceIndex].bgpConnections
        if (existingConnections) {
          savedBgpCount = Object.keys(existingConnections).length
        }
      }

      // Update when the form is new/design
      if (savedBgpCount <= index || !this.serviceObj.provisioningStatus || this.serviceObj.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        this.updateBGPConnection({ valid: true, bgpConnection: update })
        this.commitBGPConnection()
        return true
      }
      this.confirmBGPShutdown(update, row)
    },
    /**
     * Toggle the confirm modal
     */
    confirmBGPShutdown(update, row) {
      // New state
      const shutdownRestart = !row.shutdown ? this.$t('general.shut-down') : this.$t('general.enabled')
      const confirmMessage = this.$t('connections.confirm-bgp-state-change', { newState: shutdownRestart })

      // Confirm
      this.$confirm(confirmMessage, this.$t('connections.bgp-state-change-prompt'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          this.updateBGPConnection({ valid: true, bgpConnection: update })
          this.commitBGPConnection()
          this.$nextTick(() => {
            this.$emit('saveConnection')
          })
        })
        .catch(() => {
          // empty function is intentional
        })
    },

    /**
     * Delete event handler for BGP connections list
     */
    deleteBGPConnection({ index }) {
      const update = [...this.selectedInterface.bgpConnections]
      update.splice(index, 1)
      this.updateSelectedInterface({ bgpConnections: update })
    },

    // -------------------------------------------------------------------------------------------- Interface managemet

    /**
     * Update the currently selected interface
     * @param {Object} updateObject Represents the child element of a interface. Eg. bgpConnection, bfd, ipAddresses
     */
    updateSelectedInterface(updateObject) {
      let update = this.selectedInterface

      update = {
        ...update,
        ...updateObject,
      }

      const interfaceUpdate = [...this.interfaces]

      interfaceUpdate.splice(this.selectedInterfaceIndex, 1, update)
      this.interfaces = [...interfaceUpdate]
      this.emitUpdate()
    },
    /**
     * Toggle handler for enable/disable QinQ
     */
    confirmToggleQinQ(value) {
      // Confirm
      if (!value) {
        this.$confirm(this.$t('mcr-config.disable-qinq-message'), this.$t('mcr-config.confirm'), {
          confirmButtonText: this.$t('general.yes'),
          cancelButtonText: this.$t('general.no'),
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
        })
          .then(() => {
            this.enableQinQ(value)
          })
          .catch(() => {
            // empty function is intentional
          })
      } else {
        this.enableQinQ(value)
      }
    },
    /**
     * Toggle QinQ
     * @param {Boolean} value
     */
    enableQinQ(value) {
      // Reset validation errors
      this.qinqEnabled = value
      if (value) {
        this.updateSelectedInterface({ vlan: 100 })
        this.updateVLANValidation(this.selectedInterfaceIndex, true)
      } else {
        this.selectedInterfaceIndex = 0
        this.deleteInterface(1, this.interfaces.length - 1)
        this.updateSelectedInterface({ vlan: null })
      }
    },
    /**
     * @return {Number} returns a random unique vlan ID
     */
    generateRandomVlanIndex() {
      const idx = Math.round(Math.random() * this.maxVlanId)
      if (!this.vlanList.includes(idx)) {
        return idx
      }
      return this.generateRandomVlanIndex()
    },

    /**
     * Click handler Add VLAN
     */
    addInterface() {
      const nextValnIdx = this.generateRandomVlanIndex()
      this.interfaces.push({
        vlan: this.qinqEnabled ? nextValnIdx : null,
        ipAddresses: [],
        bgpConnections: [],
        ipRoutes: [],
        natIpAddresses: [],
      })

      const newIndex = this.interfaces.length - 1
      this.selectedInterfaceIndex = newIndex
      this.updateVLANValidation(this.selectedInterfaceIndex, true)
      this.emitUpdate()
    },

    /**
     * Input handler for VLAN id
     * @param {Number} value VLAN id
     */
    updateVLAN(value) {
      // Reset validation errors
      this.updateVLANValidation(this.selectedInterfaceIndex, true)
      // Check and update
      this.validateVLAN(this.selectedInterfaceIndex, value)
      this.updateSelectedInterface({ vlan: Number.parseInt(value) })
    },

    /**
     * Check VLAN is validation
     * @param {Number} index interface index
     * @param {Number} value vlan ID
     * @param {Boolean} checkUnique toggle to check if VLAN id is unique (used for existing interfaces)
     */
    validateVLAN(index, value, checkUnique = true) {
      const vlanUnique = this.vlanList.every(vlan => vlan !== value)
      let isValid = true

      // No value
      if (!value) {
        isValid = false
      }

      // Not unique
      if (checkUnique && !vlanUnique) {
        isValid = false
      }

      // Exceeds max value
      if (Number.parseInt(value) > this.maxVlanId) {
        isValid = false
      }

      this.updateVLANValidation(index, isValid)
    },

    /**
     * Click handler to delete a VLAN
     */
    confirmDeleteVLAN() {
      this.$confirm(this.$t('mcr-config.delete-vlan-message'), this.$t('mcr-config.delete-vlan'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          // Reset validation errors
          this.updateVLANValidation(this.selectedInterfaceIndex, null, true)
          const deleteIndex = this.selectedInterfaceIndex
          this.selectedInterfaceIndex = 0
          this.deleteInterface(deleteIndex, 1)
        })
        .catch(() => {
          /* Do nothing -- confirm cancelled */
        })
    },

    /**
     * Update the VLAN validation map at the given index
     * @param {Number} index VLAN interface ID
     * @param {Boolean} value true if valid, false if invalid
     */
    updateVLANValidation(index, value, remove = false) {
      if (remove) {
        this.vlanIdErrors.splice(index, 1)
      } else {
        this.vlanIdErrors.splice(index, 1, value)
      }
    },

    /**
     * Removes the interface at the given index
     * @param {String} start Array index start
     * @param {String} end Array index end
     */
    deleteInterface(start, end) {
      const update = [...this.interfaces]
      update.splice(start, end)
      this.interfaces = update
      this.emitUpdate()
    },

    // -------------------------------------------------------------------------------------------- Network

    /**
     * Handler to select NAT address
     * @param {String} value IP Address selected
     */
    selectNatAddress(value) {
      const update = !value ? [] : [value]
      this.updateSelectedInterface({ natIpAddresses: update })
    },

    /**
     * Handler to update network address
     * @param {Boolean} valid
     * @param {Array} value List of network prefixes
     */
    updateNetworkAddress({ valid, value }) {
      // Reset validation errors
      this.validationMap = { ...this.validationMap, ipAddresses: valid }

      // Update the interface with the new addresses
      if (valid) {
        this.updateSelectedInterface({ ipAddresses: value })
      } else {
        this.emitEdit()
      }
    },

    /**
     * Handler to delete network address
     * @param {String} address IP Address to remove
     */
    deleteNetworkAddress({ address }) {
      const updateAddress = address.split('/')[0]
      const update = {}

      // Check if used as NAT
      if (this.selectedNat === updateAddress) update.natIpAddresses = []

      // Remove bgp connections that use the local address
      const updateBgpConnections = this.selectedInterfaceBgpConnections.filter(connection => {
        return connection.localIpAddress !== updateAddress
      })
      update.bgpConnections = updateBgpConnections

      // Remove static routes that are in the same network
      const staticRouteIndexes = this.staticRouteNextHopsMatchingInterface.filter(route => route.interfaceIp === address).map(route => route.index)
      const staticRouteUpdate = this.selectedInterfaceIpRoutes.filter((route, index) => {
        return !staticRouteIndexes.includes(index)
      })
      update.ipRoutes = staticRouteUpdate

      // Update the interface with the new bgp connections
      this.updateSelectedInterface(update)
    },

    /**
     * Handler to update static routes
     * @param {Boolean} valid
     * @param {Array} value List of the static route objects
     * @todo Need to add network prefix list to component for checking
     */
    updateStaticRoutes({ valid, value }) {
      // Reset validation errors
      this.validationMap = { ...this.validationMap, ipRoutes: valid }

      // Update the interface with new routes
      if (valid) {
        this.updateSelectedInterface({ ipRoutes: value })
      }
      this.emitEdit()
    },
    /**
     * Tab click handler to select interface
     * @param {Number} index Selected tab index
     */
    selectVlanInterface({ index }) {
      this.selectedInterfaceIndex = index
    },
    /**
     * Emits the start of editing
     */
    emitEdit() {
      this.$emit('edit')
    },
    /**
     * Emits the interfaces to parent config
     */
    emitUpdate() {
      this.$emit('update', {
        isValid: this.isConfigurationValid,
        partnerConfig: {
          connectType: 'VROUTER',
          interfaces: this.interfaces,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mcrConfig {
  .header {
    position: relative;
    padding-bottom: 1rem;
    text-align: center;
    flex-grow: 1;

    .qinqToggle {
      text-align: left;
    }
  }
  h4 {
    margin-bottom: 2rem;
  }
  h5 {
    font-size: 1.6rem;
  }
  label {
    font-weight: bold;
  }
  hr {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .nat {
    border-left: 1px solid #dcdfe6;
  }
  .natStatus {
    span {
      margin-left: 1rem;
    }
    .greenIcon {
      color: green;
    }
    .redIcon {
      color: red;
    }
  }

  .el-tab-pane {
    padding: 0.5rem 0;
  }

  .section {
    margin-top: 2rem;
  }

  .section-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    .col:first-child {
      flex-basis: 50%;
    }
  }

  .networkPrefixes {
    width: 45%;
  }

  .vlanTabs {
    position: relative;
    margin-top: 2rem;
    .addVlanBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .vlanInputGroup {
    display: flex;
    align-items: baseline;
    flex-grow: 0;
    width: 500px;

    .deleteVlanBtn {
      margin-left: 1rem;
    }

    .vlanInput {
      display: block;
      width: 100px;
      input.el-input__inner {
        width: 100px;
      }
    }
  }

  .bgpConnections {
    .flex-row {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
  }

  dl.bfdDefinitions {
    display: flex;
    flex-wrap: wrap;
    .flex-group {
      flex-basis: 100%;
      display: flex;
      margin-bottom: 1rem;
      dt {
        font-weight: bold;
        flex-basis: 150px;
      }
    }
  }
}
</style>

<style lang="scss">
.bgpConnectionEditor {
  .el-dialog {
    width: 75vw;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>
